import {
    STORES_GET_STORES,
    STORES_GET_STORES_ERROR,
    STORES_GET_STORES_SUCCESS,
    STORES_GET_MY_POSITION,
    STORES_GET_MY_POSITION_SUCCESS,
    STORES_GET_MY_POSITION_ERROR,
    STORES_GET_INFO_MARKER
} from './types';
import Translate from '../utils/Translate';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';

export const getStores = () => async dispatch => {
    dispatch({ type: STORES_GET_STORES });
    const params = {
        'url': `/extapi/Logged/ListComercios`, 
        'method': 'GET',
        'headers': { // Para API posta
          'content-type': 'application/json;charset=utf-8',
          'authorization': `Bearer ${sessionStorage.getItem('Token')}`
        }
      };
    //   try {
        
        BackOfficeEndpoints(params)
        .then(response => {
            dispatch({ type: STORES_GET_STORES_SUCCESS, payload: response.data.data });
        })
        .catch(err => {
          if (err.response.status === 401) {
            const errorMessage = Translate.t('Sesion expirada. Por favor inicie sesion nuevamente');
            dispatch({ type: STORES_GET_STORES_ERROR, payload: errorMessage });
          } else {
              dispatch({ type: STORES_GET_STORES_ERROR, payload: err.response });
          }
        });
        // dispatch({ type: STORES_GET_STORES_SUCCESS, payload: response.data.data });
    //   } catch(err) {
        //   if(err.response.status !== 401){
        //     dispatch({ type: STORES_GET_STORES_ERROR, payload: err.response });
        //   }
    //   }
}

export const getMyPosition = () => async dispatch => {
    dispatch({ type: STORES_GET_MY_POSITION });
    navigator.geolocation.getCurrentPosition(position => {
        dispatch({ type: STORES_GET_MY_POSITION_SUCCESS, payload: position.coords });// Resultados Posta
      }, err => {
        dispatch({ type: STORES_GET_MY_POSITION_ERROR, payload: err.message });
      });
}

export const getInfoMarker = (props, marker) => ({
    type: STORES_GET_INFO_MARKER,
    payload: {props, marker}
})