import React, { useEffect } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { refundMakeRefund, refundResetState, resetToResultPayment } from '../actions';
import { MenuBar } from '../utils';
import AutoLogout from '../utils/AutoLogout';
import '../static/css/refund.css';
import icon from '../static/img/Assets/devolver.png';
import Translate from '../utils/Translate';
import { useForm } from 'react-hook-form';

const Refund = () => {

  const {
    toResultPayment,
    errorMessage,
    amount,
    clientNumber,
    loading
  } = useSelector(({ refund }) => refund);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  
  useEffect(() => {
    dispatch(refundResetState()); 
    return () => dispatch(resetToResultPayment());
  },[]);

  const onSubmit = formValues => {
    dispatch(refundMakeRefund(formValues));
  }

  const renderButton = () => {
    if (loading) {
      return <div className="loaderContainerRefund"><Loader active /></div>;
    }

    return <button
      type="submit"
      className="TransferBtn">
        {Translate.t('Transferir')}
    </button>;
  }

  if (toResultPayment === true) {
    return <Redirect
      to={{
        pathname: '/resultpayment',
        errorMessage,
        amount,
        clientNumber,
        operationType: 'refund'
      }}
    />;
  }

  return (
    <React.Fragment>
      <AutoLogout />
      <MenuBar active="refund" />
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <img src={icon} className="IconWallet" alt="logo" />
        </div>
      </div>
      <div className="BackgroundAmountInputQR">
        <br />
        <br />
        <div className="ui one column stackable center aligned page grid">
          <div className="column twelve wide">
            <span className="TitleScreen">{Translate.t('Reembolso')}</span>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div className="ui one column stackable center aligned page grid">
        <div className="column seven wide">

          <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
            <div className="inputField">
              <input
                name="toClientNumber"
                placeholder={Translate.t('NRO DE CLIENTE')}
                type="number"
                className="Inputuser"
                ref={register({ required: true, touched: true })} />
              {errors.toClientNumber?.type === 'required' && 
                <div className="errorMessage refundErMsg">
                  {Translate.t('Nro de cliente no debe estar vacío')}
                </div>}
              {errors.toClientNumber?.type === 'pattern' && 
                <div className="errorMessage refundErMsg">
                  {Translate.t('Nro. de cliente no puede ser con decimales')}
                </div>}
            </div>
            <div className="inputField">
              <input
                name="amountToRefund"
                placeholder={Translate.t('Importe')}
                type="number"
                step="0.01"
                className="Amountinput errorFieldInput"
                ref={register({ required: true, pattern: /^[0-9.]+$/, touched: true })}
              />
              {errors.amountToRefund?.type === "required" && 
                <div className="errorMessage refundErMsg">
                  {Translate.t('Importe no debe estar vacío')}
                </div>}
              {errors.amountToRefund?.type === "pattern" && 
                <div className="errorMessage refundErMsg">
                  {Translate.t('Importe debe ser numérico')}
                </div>}
            </div>
            <div className="inputField">
              <input
                name="employeeNumber"
                type="number"
                placeholder={Translate.t('NroEmpleado')}
                className='Amountinput'
                ref={register({ required: false, touched: true })}
                size="large" />
            </div>
            {renderButton()}
          </form>
        </div>
      </div>
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <Message compact negative hidden={!errorMessage}>
            <Message.Header>Error</Message.Header>
            <p>{errorMessage}.</p>
          </Message>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Refund;