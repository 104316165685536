import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Message, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { MenuBar } from '../utils';
import AutoLogout from '../utils/AutoLogout';
import '../static/css/wallet.css';
import icon from '../static/img/Assets/empresa_icon.png';
import Translate from '../utils/Translate';
import {
  walletGetUserData,
  walletGenQR,
  walletResetState,
  walletQrWithoutAmount,
  walletResetToQrInvoice
} from '../actions';

const Wallet = props => {

  const {
    errorMessage,
    codigoDePagos,
    idCliente,
    nombre,
    email,
    loading,
    toQrGenerator,
    qrWithoutAmount
  } = useSelector(({ wallet }) => wallet);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues, setValue } = useForm();
  useEffect(() => {
    dispatch(walletResetState());
    dispatch(walletGetUserData());
    return () => dispatch(walletResetToQrInvoice());
  },[]);
  
  const toggle = () => {
    dispatch(walletQrWithoutAmount());
  }

  const onSubmit = formValues => {
    dispatch(walletGenQR(formValues));
  }
    
  const renderButton = () => {
    if (loading) {
      return <div className="loaderContainer"><Loader active /></div>;
    }
    return (
      <button
        type="submit"
        className="GenerateQR">
          {Translate.t('Generar')}
      </button>
    );
  }

  if (toQrGenerator === true) {
    return <Redirect to={{ pathname: '/qrinvoice',  }} />;
  }
  if(getValues("check")) {
    setValue("AmountInput", null)
  }
  return (
    <div className="container">
      <MenuBar
        active="wallet"
        idCliente={idCliente}
        codigoDePagos={codigoDePagos}
        email={email}
        nombre={nombre}
      />
      <AutoLogout expires_in={props.location.expires_in} history={props.history} />
      <div className="WalletContainer">
        <div className="ui one column stackable center aligned page grid">
          <div className="column twelve wide">
            <img src={icon} className="IconWallet" alt="logo" />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
          <div className="BackgroundAmountInput">
            <br />
            <br />
            <div className="ui one column stackable center aligned page grid">
              <div className="column sixteen wide">
                <span className="EnterAmountLegend">{Translate.t('Ingrese importe')}</span>
              </div>
            </div>
            <div className="ui two column stackable center aligned page grid">
              <div className="column seven wide">
                <div className="inputField">
                  <input
                    name="AmountInput"
                    type="number"
                    step="0.01"
                    placeholder={Translate.t('Importe')}
                    className='Amountinput'
                    size="large"
                    ref={register({ required: !getValues("check"), min: 0.01 })}
                    disabled={getValues("check")} />
                </div>
                <div className="inputField">
                  <input
                    name="employeeNumber"
                    type="number"
                    placeholder={Translate.t('NroEmpleado')}
                    ref={register({ required: false, touched: true })}
                    className='Amountinput'
                    size="large" />
                </div>
                  {errors.AmountInput?.type === "min" && 
                    <div className="errorMessage">
                      {Translate.t('El importe debe ser mayor que 0.00')}
                    </div>
                  }
                  {errors.AmountInput?.type === "required" && 
                    <div className="errorMessage">
                      {Translate.t('Defina importe o genere qr sin importe')}
                    </div>
                  }
              </div>
            </div>
            <div className="ui one column stackable center aligned page grid">
              <div className="checkImport">
                <input
                  name="check"
                  type="checkbox"
                  className="checkInput"
                  checked={qrWithoutAmount}
                  onChange={toggle}
                  ref={register}
                />
                <label className="checkLabel">{Translate.t('Generar QR sin importe')}</label>
              </div>
            </div>
            <div className="ui one column stackable center aligned page grid">
              <div className="column seven wide">
                {qrWithoutAmount ?
                  <div className="warningMessage">
                    {Translate.t('Advertencia cobro sin importe')}
                  </div>
                : ''}
              </div>
            </div>
          </div>
          <div className="ui one column stackable center aligned page grid">
            <div className="column twelve wide">
              {renderButton()}
            </div>
            <Message compact negative hidden={!errorMessage}>
              <Message.Header>Error</Message.Header>
              <p>{errorMessage}.</p>
            </Message>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Wallet;


