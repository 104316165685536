import {
    HISTORY_GET_DATA,
    HISTORY_GET_DATA_SUCCESS,
    HISTORY_GET_DATA_ERROR,
    SET_TRANSACTIONS_PER_PAGE,
    SET_TRANSACTIONS_PARAMETERS,
    HISTORY_RESET_STATE
} from '../actions/types';

const INITIAL_STATE = {
    transactions: [],
    errorMessage: '',
    transactionsPerPage: 10,
    valOpType: '<-',
    summary: false,
    txType: 'CVCO',
    loading: false
};

export default (state = INITIAL_STATE, action ) => {
    switch(action.type){
        case HISTORY_GET_DATA:
            return { ...state, loading: true }
        case HISTORY_GET_DATA_ERROR:
            return { ...state, loading: false, errorMessage: action.payload }
        case HISTORY_GET_DATA_SUCCESS:
            return { ...state, loading: false, errorMessage: '', transactions: action.payload }
        case SET_TRANSACTIONS_PER_PAGE:
            return { ...state, transactionsPerPage: action.payload }
        case SET_TRANSACTIONS_PARAMETERS:
            return { 
                ...state, 
                valOpType: action.payload.valOpType, 
                txType: action.payload.txType, 
                summary: action.payload.summary 
            }
        case HISTORY_RESET_STATE:
            return { ...state, ...INITIAL_STATE }
        default:
            return state;
    }
}