import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';
import Translate from './Translate';

const MenuDrawer = props => (
  <nav hidden={props.hidden} className='SideMenu'>
    <ul className='LiContainer'>
      <li className='SideMenuOptionFirst'>
        <Link
          className={`OptionSM ${props.active === 'stores' ? 'active': ''}`}
          to="/stores"
        >
          {Translate.t('COMERCIOS')}
        </Link>
      </li>
      <Divider />
      <li className='SideMenuOption'>
        <Link
          className={`OptionSM ${props.active === 'set_currency' ? 'active': ''}`}
          to="/setcurrency"
        >
          {Translate.t('ELEGIR MONEDA')}
        </Link>
      </li>
      <Divider />
      <li className='SideMenuOption'>
        <Link
          className={`OptionSM ${props.active === 'wallet' ? 'active': ''}`}
          to="/wallet"
        >
          {Translate.t('COBROS')}
        </Link>
      </li>
      <Divider />
      <li className='SideMenuOption'>
        <Link
          className={`OptionSM ${props.active === 'refund' ? 'active': ''}`}
          to="/refund"
        >
          {Translate.t('REEMBOLSO')}
        </Link>
      </li>
      <Divider />
      <li className='SideMenuOption'>
        <Link
          className={`OptionSM ${props.active === 'history' ? 'active': ''}`}
          to="/history"
        >
          {Translate.t('HISTORIAL')}
        </Link> 
      </li>
      <Divider />
      <li className='SideMenuOptionLast'>
        <Link
          className={`OptionSM ${props.active === 'cryptos_price' ? 'active': ''}`}
          to="/cryptocurrrenciesprice"
        >
          {Translate.t('COTIZACIÓN CRIPTOS')}
        </Link> 
      </li>
    </ul>
  </nav>
);

export { MenuDrawer };