import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
	// Project Settings => Add Firebase to your web app
  messagingSenderId: "255421492562",
  projectId: "eurocoinpay-65b34",
  apiKey: "AIzaSyBKr5_wphuy3kPj01Mxu7q30aR5Hw9B6-o",
  appId: "1:255421492562:ios:4c0280d0b7532ec8781e8b",
  measurementId: "G-TYKKGKLX42"
});
const messaging = initializedFirebaseApp.messaging();
messaging.usePublicVapidKey(
	// Project Settings => Cloud Messaging => Web Push certificates
  "BCgenxY6lSRgJx8SisH1e-Jn1w6nR-nY-ayO6fP3xT-hA9n7_LKOSjPlOfsnmUP15Ydz0-WeYjkTqQMZCYdNftU"
);
export { messaging };