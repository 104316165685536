import {
    GET_CURRENCIES,
    GET_CURRENCIES_SUCCESS,
    GET_CURRENCIES_ERROR,
    CHANGE_CURRENCY
  } from '../actions/types';

  const INITIAL_STATE = {
      currenciesList: [],
      errorMessage: '',
      loading: false,
      selectedCurrency: ''
  }

  export default ( state = INITIAL_STATE, action ) => {
      switch (action.type) {
          case GET_CURRENCIES:
              return { ...state, loading: true, errorMessage: '' };
              
          case GET_CURRENCIES_SUCCESS:
              return { ...state, currenciesList: action.payload, loading: false, errorMessage: '' }; 
              
          case GET_CURRENCIES_ERROR:
              return { ...state, loading: false, errorMessage: action.payload };
              
          case CHANGE_CURRENCY:
              return { ...state, selectedCurrency: action.payload };
              
          default:
              return state;
              
      }
  };