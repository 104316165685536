import React, { useEffect, useState } from 'react';
import ReactTimeout from 'react-timeout';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authLogout } from '../actions';

const AutoLogout = (props) => {

  const dispatch = useDispatch();
  const [ token, setToken ] = useState(true);
  const timeLogOut = props.expires_in || sessionStorage.getItem('expires_in')
  // const timeLogOut = 18000;
  
  useEffect(() => {
    const timer = setTimeout(() => setToken(false), timeLogOut)
    return () => clearTimeout(timer);
  },[]);
      
  if (!token) {
    dispatch(authLogout());
    return <Redirect to='/session=expired' />;
  } else {
    return <div></div>;
  }
}


export default AutoLogout;
