import React from 'react';
import { MenuBar } from '../utils';
import AutoLogout from '../utils/AutoLogout';
import '../static/css/history.css';
import icon from '../static/img/Assets/companyui_history.png';
import Translate from '../utils/Translate';
import Paginator from '../utils/Paginator';

const History = () => {
  return (
    <div>
      <AutoLogout />
      <MenuBar active="history" />
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <img src={icon} className="IconWallet" alt="logo" />
        </div>
      </div>
      <div className="BackgroundAmountInputQR">
        <br />
        <br />
        <div className="ui one column stackable center aligned page grid">
          <div className="column twelve wide">
            <span className="TitleScreen">{Translate.t('Historial')}</span>
          </div>
        </div>
        <br />
        <br />
      </div>

      <Paginator />

      <br />
    </div>
  );
}
export default History;
