import {
    STORES_GET_STORES,
    STORES_GET_STORES_SUCCESS,
    STORES_GET_STORES_ERROR,
    STORES_GET_MY_POSITION,
    STORES_GET_MY_POSITION_SUCCESS,
    STORES_GET_MY_POSITION_ERROR,
    STORES_GET_INFO_MARKER
} from '../actions/types';

const INITIAL_STATE = {
    storesList: [],
    errorMessage: '',
    loading: false,
    latitude: null,
    longitude: null,
    errorMessageGeo: '',
    loadingGeo: false,
    selectedPlace: {},
    activeMarker: {}
};

export default (state = INITIAL_STATE, action) => {
   switch(action.type){
       case STORES_GET_STORES:
           return { ...state, loading: true, errorMessage: '' }
       case STORES_GET_STORES_SUCCESS:
           return { ...state, storesList: action.payload, loading: false, errorMessage: '' }
       case STORES_GET_STORES_ERROR:
           return { ...state, loading: false, errorMessage: action.payload }
       case STORES_GET_MY_POSITION:
           return { ...state, loadingGeo: true, errorMessageGeo: '' }
       case STORES_GET_MY_POSITION_SUCCESS:
           return { 
               ...state, 
               latitude: action.payload.latitude, 
               longitude: action.payload.longitude, 
               errorMessageGeo: '', 
               loadingGeo: false 
            }
        case STORES_GET_MY_POSITION_ERROR:
            return { ...state, loadingGeo: false, errorMessageGeo: action.payload }
        case STORES_GET_INFO_MARKER:
            return { 
                ...state, 
                selectedPlace: action.payload.props, 
                activeMarker: action.payload.marker 
            }
       default:
           return state;

   } 
};