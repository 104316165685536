export const AUTH_USER_LOGIN = 'auth_user_login';
export const AUTH_USER_LOGIN_SUCCESS = 'auth_user_login_success';
export const AUTH_USER_LOGIN_ERROR = 'auth_user_login_error';
export const AUTH_USER_LOGOUT = 'auth_user_logout';
export const AUTH_USER_GET_DATA = 'auth_user_get_data';
export const AUTH_CLIENT_NUMBER_CHANGED = 'auth_client_number_changed';
export const AUTH_PASSWORD_CHANGED = 'auth_password_changed';
export const AUTH_USER_SET_MESSAGE = 'auth_user_set_message';
export const AUTH_USER_REDIRECT = 'auth_user_redirect';

export const WALLET_GET_USER_DATA = 'wallet_get_user_data';
export const WALLET_SET_USER_DATA = 'wallet_set_user_data';
export const WALLET_GOT_ERROR = 'wallet_got_error';
export const WALLET_GO_QR_SCREEN = 'wallet_go_qr_screen';
export const WALLET_RESET_STATE = 'wallet_reset_state';
export const WALLET_RESET_TO_QR_GENERATOR = 'wallet_reset_to_qr_generator';
export const WALLET_QR_WITHOUT_AMOUNT = 'wallet_qr_without_amount';

export const QRINVOICE_CANCEL_CHARGE = 'qrinvoice_cancel_charge';
export const QRINVOICE_ERROR = 'qrinvoice_error';
export const QRINVOICE_RESET_STATE = 'qrinvoice_reset_state';
export const QRINVOICE_PAYMENT_SUCCESS = 'qrinvoice_payment_success';
export const QRINVOICE_RESET_TO_RESULT_PAYMAENT = 'qrinvoice_reset_to_result_payment';

export const CRYPTOSPRICE_GET_CURRENCIES = 'cryptosprice_get_currencies';
export const CRYPTOSPRICE_GET_CURRENCIES_SUCCESS = 'cryptosprice_get_currencies_success';
export const CRYPTOSPRICE_GET_CURRENCIES_ERROR = 'cryptosprice_get_currencies_error';

export const REFUND_MAKE_REFUND = 'refund_make_refund';
export const REFUND_MAKE_REFUND_SUCCESS = 'refund_make_refund_success';
export const REFUND_MAKE_REFUND_ERROR = 'refund_make_refund_error';
export const REFUND_RESET_STATE = 'refund_reset_state';
export const REFUND_RESET_TORESULTPAYMENT = 'refund_reset_toresultpayment';

export const GET_CURRENCIES = 'get_currencies';
export const GET_CURRENCIES_SUCCESS = 'get_currencies_success';
export const GET_CURRENCIES_ERROR = 'get_currencies_error';
export const CHANGE_CURRENCY = 'change_currency';

export const STORES_GET_STORES = 'stores_get_stores';
export const STORES_GET_STORES_SUCCESS = 'stores_get_stores_success';
export const STORES_GET_STORES_ERROR = 'stores_get_stores_error';
export const STORES_GET_MY_POSITION = 'stores_get_my_position';
export const STORES_GET_MY_POSITION_SUCCESS = 'stores_get_my_position_success';
export const STORES_GET_MY_POSITION_ERROR = 'stores_get_my_position_error';
export const STORES_GET_INFO_MARKER = 'stores_get_info_marker';

export const HISTORY_GET_DATA = 'history_get_data';
export const HISTORY_GET_DATA_SUCCESS = 'history_get_data_success';
export const HISTORY_GET_DATA_ERROR = 'history_get_data_error';
export const SET_TRANSACTIONS_PER_PAGE = 'set_transactions_per_page';
export const SET_TRANSACTIONS_PARAMETERS = 'set_transactions_parameters';
export const HISTORY_RESET_STATE = 'history_reset_state';
