import React from 'react';
import { Link } from 'react-router-dom';
import { ProfilePopUp } from './ProfilePopUp';
import MenuPopUp from './MenuPopUp';
import Translate from './Translate';
import '../static/css/menu.css';
import Logo from '../static/img/Assets/Isologo.png';


const MenuBar = (props) => {
  
  const renderMenu = (props) => {
    const { active, idCliente, codigoDePagos, email, nombre } = props;

    if (window.screen.width <= 1024) {
      return (
        <div className="ImgOptGlue">
          <MenuPopUp active={props.active} />
          <img src={Logo} className="ImgLogo" alt="logo" />
          <div className="OptContainer">
            <div className="Option profile">
              <ProfilePopUp
                idCliente={props.idCliente}
                codigoDePagos={props.codigoDePagos}
                email={props.email}
                nombre={props.nombre}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="ImgOptGlue">
          <img src={Logo} className="ImgLogo" alt="logo" />
          <div className="OptContainer">
            <Link
              className={`Option pages ${active === 'stores' ? 'active': ''}`}
              to="/stores">
              {Translate.t('COMERCIOS')}
            </Link>
            <Link
              className={`Option pages ${active === 'set_currency' ? 'active': ''}`}
              to="/setcurrency">
              {Translate.t('ELEGIR MONEDA')}
            </Link>
            <Link
              className={`Option pages ${active === 'wallet' ? 'active': ''}`}
              to="/wallet">
              {Translate.t('COBROS')}
            </Link>
            <Link
              className={`Option pages ${active === 'refund' ? 'active': ''}`}
              to="/refund">
              {Translate.t('REEMBOLSO')}
            </Link>
            <Link
              className={`Option pages ${active === 'history' ? 'active': ''}`}
              to="/history">
              {Translate.t('HISTORIAL')}
            </Link>
            <Link
              className={`Option pages ${active === 'cryptos_price' ? 'active': ''}`}
              to="/cryptocurrrenciesprice">
              {Translate.t('COTIZACIÓN CRIPTOS')}
            </Link>
            {/* <Link
              className={`Option pages ${active === 'backoffice' ? 'active': ''}`}
              to="/uservalidation">
              Backoffice
            </Link> */}
            <Link
              className={`Option pages ${active === 'pagos_rt' ? 'active': ''}`}
              to="/pagosrealtime">
              {Translate.t('Pagos Real Time')}
            </Link>
            <div className="Option profile" >
              <ProfilePopUp
                idCliente={idCliente}
                codigoDePagos={codigoDePagos}
                email={email}
                nombre={nombre}
              />
            </div>
          </div>
        </div>
      );
    }
  }
  
  return (
    <div className="MenuContainer">
        {renderMenu(props)}
    </div>
  );
  
}
export { MenuBar };