import {
  REFUND_MAKE_REFUND,
  REFUND_MAKE_REFUND_SUCCESS,
  REFUND_MAKE_REFUND_ERROR,
  REFUND_RESET_STATE,
  REFUND_RESET_TORESULTPAYMENT
} from './types';
import Translate from '../utils/Translate';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';

export const refundResetState = () => dispatch => dispatch({ type: REFUND_RESET_STATE });

export const resetToResultPayment = () => { return { type: REFUND_RESET_TORESULTPAYMENT } };

export const refundMakeRefund = formValues => async dispatch => {
  dispatch({ type: REFUND_MAKE_REFUND });
  const options = {
    'nr_cliente': formValues.toClientNumber,
    'ImporteEuros': parseFloat(formValues.amountToRefund)
  };

  if (formValues.employeeNumber) {
    options.EmployeeId = formValues.employeeNumber;
  }
  const token = sessionStorage.getItem('Token');
  const params = {
    'method': 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: options,
    'url': `/extapi/Logged/SendRefundEuros`
  };
  try {
    const resp = await BackOfficeEndpoints(params);
    const info = resp.data;
    if (info.errorCode === 0) {
      dispatch({ type: REFUND_MAKE_REFUND_SUCCESS, payload: formValues });
    } else if (info.errorCode === 6) {
      dispatch({ type: REFUND_MAKE_REFUND_ERROR, payload: Translate.t('Fondos insuficientes') });
    } else {
      dispatch({ type: REFUND_MAKE_REFUND_ERROR, payload: Translate.t('Intente mas tarde') });
    }
  } catch (err) {
    dispatch({ type: REFUND_MAKE_REFUND_ERROR, payload: err.toString() });
    // if (err.response.status === 401) {
    //   this.setState({ errorMessage: Translate.t('Sesion expirada. Por favor inicie sesion nuevamente') });
    // }
  }
}