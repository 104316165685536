import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import '../static/css/main.css';
import Login from './Login';
import QrInvoice from './QrInvoice';
import NotFound from '../utils/NotFound.js';
import History from './History';
import CryptosPrice from './CryptosPrice';
import Wallet from './Wallet';
import Refund from './Refund';
import ResultPayment from './ResultPayment';
// import BackOffice from './BackOffice';
import UserValidation from './UserValidation';
import Stores from './Stores';
import SetCurrency from './SetCurrency';
import LivePayments from './LivePayments';

const App = () => {
  return (
    <Router hashType="slash">
      <Switch>
        <Route exact path='/' component={Login} />
        <Route exact path='/session=expired' component={Login} />
        <PrivateRoute exact path='/qrinvoice' component={QrInvoice} />
        <PrivateRoute exact path='/history' component={History} />
        <PrivateRoute exact path='/cryptocurrrenciesprice' component={CryptosPrice} />
        <PrivateRoute exact path='/refund' component={Refund} />
        <PrivateRoute exact path='/wallet' component={Wallet} />
        <PrivateRoute exact path='/resultpayment' component={ResultPayment} />
        {/* <PrivateRoute exact path='/backoffice' component={BackOffice} /> */}
        <PrivateRoute exact path='/stores' component={Stores} />
        <PrivateRoute exact path='/setcurrency' component={SetCurrency} />
        <PrivateRoute exact path='/uservalidation' component={UserValidation} />
        <PrivateRoute exact path='/pagosrealtime' component={LivePayments} />

        <Route exact path='/404' component={NotFound} />
        <Redirect to='/404' />
      </Switch>
    </Router>
  );
}

export default App;
