import {
  WALLET_GET_USER_DATA,
  WALLET_SET_USER_DATA,
  WALLET_GOT_ERROR,
  WALLET_GO_QR_SCREEN,
  WALLET_RESET_STATE,
  WALLET_RESET_TO_QR_GENERATOR,
  WALLET_QR_WITHOUT_AMOUNT
} from '../actions/types';

const INIITIAL_STATE = {
  errorMessage: '',
  amount: '',
  employeeNumber: '',
  codigoDePagos: '',
  idCliente: '',
  nombre: '',
  email: '',
  loading: false,
  pais: '',
  toQrGenerator: false,
  qrWithoutAmount: false
};

export default (state = INIITIAL_STATE, action) => {
  switch (action.type) {
    case WALLET_RESET_STATE:
      return { ...state, toQrGenerator: false, amount: '', qrWithoutAmount: false };
    case WALLET_RESET_TO_QR_GENERATOR:
      return { ...state, toQrGenerator: false };
    case WALLET_GET_USER_DATA:
      return { ...state, loading: true };
    case WALLET_SET_USER_DATA:
      const { CodigoParaEnvios, Id, Nombre, Email } = action.payload;
      return { 
        ...state,
        loading: false,
        errorMessage: '',
        codigoDePagos: CodigoParaEnvios,
        idCliente: Id,
        nombre: Nombre,
        email: Email
      };
    case WALLET_GO_QR_SCREEN:
      const { amountFormatted, employeeNumber } = action.payload;
      return { ...state, toQrGenerator: true, amount: amountFormatted, employeeNumber };
    case WALLET_QR_WITHOUT_AMOUNT:
      return { ...state, qrWithoutAmount: !state.qrWithoutAmount };
    case WALLET_GOT_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};