import {
  AUTH_USER_LOGIN,
  AUTH_USER_LOGIN_SUCCESS,
  AUTH_USER_LOGIN_ERROR,
  AUTH_USER_LOGOUT,
  AUTH_USER_GET_DATA,
  AUTH_USER_SET_MESSAGE,
  AUTH_USER_REDIRECT
} from '../actions/types';

const INITIAL_STATE = {
  token: '',
  expires_in: 0,
  errorMessage: '',
  loading: false,
  userData: {},
  fcmToken: '',
  toWallet: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER_LOGIN:
      return { ...state, loading: true, errorMessage: '' };
    case AUTH_USER_LOGIN_SUCCESS:
      return { ...state, token: action.payload, loading: false };
    case AUTH_USER_LOGIN_ERROR:
      return { ...state, errorMessage: action.payload, loading: false };
    case AUTH_USER_LOGOUT:
      return { ...state, ...INITIAL_STATE };
    case AUTH_USER_GET_DATA:
      return { ...state, userData: action.payload };
    case AUTH_USER_SET_MESSAGE:
      return { ...state, errorMessage: action.payload };
    case AUTH_USER_REDIRECT:
      return { ...state, toWallet: action.payload[0], expires_in: action.payload[1] };
    default:
      return state;
  }
};