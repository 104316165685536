import React from 'react';
import Maps from '../utils/Maps';
import { MenuBar } from '../utils';
import AutoLogout from '../utils/AutoLogout';
import icon from '../static/img/Assets/map_icon.png';
import Translate from '../utils/Translate';

const Stores = () => {
  return (
    <div className="">

      <AutoLogout />
      <MenuBar active='stores' />
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <img src={icon} className="IconWallet" alt='logo' />
        </div>
      </div>
      <div className="BackgroundAmountInputQR">
        <br />
        <br />
        <div className="ui one column stackable center aligned page grid">
          <div className="column twelve wide">
            <span className="TitleScreen">{Translate.t('Comercios asociados')}</span>
          </div>
        </div>
      </div>
      <br />
      <div className="ui one column stackable center aligned page grid">
        <div className="column sixteen wide map">
          <Maps />
        </div>
      </div>
      <br /><br />
    </div>
  );
}
export default Stores;
