import {
  AUTH_USER_LOGIN,
  AUTH_USER_LOGIN_SUCCESS,
  AUTH_USER_LOGIN_ERROR,
  AUTH_USER_LOGOUT,
  AUTH_USER_GET_DATA,
  AUTH_USER_SET_MESSAGE,
  AUTH_USER_REDIRECT
} from './types';
import qs from 'qs';
import Translate from '../utils/Translate';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';

export const authUserLogin = formValues => async dispatch => {

  const { clientNumber, passwd } = formValues;

  dispatch({ type: AUTH_USER_LOGIN });

  const params = {  username: clientNumber, password: passwd, 'grant_type': 'password' };
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(params),
    url: `/extapi/token`
  }; 
  
  try {
    const response = await BackOfficeEndpoints(options);
    const { data: { access_token } } = response;
    authUserLoginSuccess(dispatch, { access_token });
  
    const expireTime = 18000000;
    sessionStorage.setItem('pwd', passwd);
    sessionStorage.setItem('Token', access_token);
    sessionStorage.setItem('expires_in', expireTime);
    authGetUserData(dispatch, { token: access_token, expireTime });
  } catch (err) {
    if (err.message === "Network Error") {
      authUserError(dispatch, Translate.t('Compruebe su conexión a internet'));
    } else if (err.response.data.error === "invalid_grant") {
      authUserError(dispatch, Translate.t('Cliente o clave incorrectos'));
    }
  }
};

const authUserLoginSuccess = (dispatch, { access_token }) => {
  dispatch({ type: AUTH_USER_LOGIN_SUCCESS, payload: access_token });
};

export const authUserSetMessage = sessionExpired => {
  return dispatch => {
    if (sessionExpired.session === 'expired') {
      sessionStorage.clear();
      dispatch({ 
        type: AUTH_USER_SET_MESSAGE, 
        payload: Translate.t('La sesión ha expirado, por favor inicie sesión nuevamente') 
      });
    }
  }
};

const authUserError = (dispatch, message) => {
  dispatch({ type: AUTH_USER_LOGIN_ERROR, payload: message });
};

const authCheckIfCommerce = (dispatch, expireTime, info) => {
  const { data } = info;
  dispatch({ type: AUTH_USER_GET_DATA, payload: data});
  data.EsComercio ? authGoToWallet(dispatch, true, expireTime)
  : authUserError(dispatch, Translate.t('Solo usuarios tipo comercio'));
};

const authGetUserData = async (dispatch, { token, expireTime }) => {
  const params = {
    'method': 'GET',
    headers: {
      'content-type': 'application/json;charset=utf-8',
      'authorization': `Bearer ${token}`
    },
    'url': `/extapi/Logged/Profile`
  };
  try {
    const response = await BackOfficeEndpoints(params);
    authCheckIfCommerce(dispatch, expireTime, response.data);
  } catch (err) {
    if (err.message === "Network Error") {
      authUserError(dispatch, Translate.t('Compruebe su conexión a internet'));
    } else if (err.response.status === 401) {
      authUserError(dispatch, Translate.t('Sesion expirada. Por favor inicie sesion nuevamente'));
    }
  }
};

const authGoToWallet = (dispatch, toWallet, expires_in) => {
  const data = [toWallet, expires_in];
  dispatch({ type: AUTH_USER_REDIRECT, payload: data });
};

export const authLogout = (dispatch) => {
  sessionStorage.removeItem('Token');
  return { type: AUTH_USER_LOGOUT };
};