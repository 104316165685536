import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import walletReducer from './walletReducer';
import qrInvoiceReducer from './qrInvoiceReducer';
import cryptosPriceReducer from './cryptosPriceReducer';
import refundReducer from './refundReducer';
import setCurrencyReducer from './setCurrencyReducer';
import storesReducer from './storesReducer';
import historyReducer from './historyReducer';

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  wallet: walletReducer,
  qrInvoice: qrInvoiceReducer,
  cryptosPrice: cryptosPriceReducer,
  refund: refundReducer,
  setCurrency: setCurrencyReducer,
  stores: storesReducer,
  history: historyReducer
});