import React, { useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { messaging } from '../init-fcm';
import { MenuBar } from '../utils';
import AutoLogout from '../utils/AutoLogout';
import '../static/css/qrinvoice.css';
import icon from '../static/img/Assets/empresa_icon.png';
import Translate from '../utils/Translate';
import {
  qrInvoiceUpdateFirebaseID,
  qrInvoiceCancel,
  qrInvoicePermissionDenied,
  qrInvoiceResetState,
  qrInovicePaymentSuccess
} from '../actions';

const QrInvoice = props => {

  const {
    errorMessage,
    amountPaid,
    toWallet,
    toResultPayment,
    currencySymbol,
    clientName,
  } = useSelector( state => state.qrInvoice );
  const { codigoDePagos, amount, employeeNumber } = useSelector( state => state.wallet );

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.screen.width >= 1024) {
      //************************************************************************************************************FIREBASE_____________________________
      messaging.requestPermission()
      .then(async () => {
        let token = await messaging.getToken();
        dispatch(qrInvoiceUpdateFirebaseID(token));
        return token;
      })
      .catch(err => {
        dispatch(qrInvoicePermissionDenied(Translate.t('Error al obtener permisos para recibir notificaciones')));
      });
      navigator.serviceWorker.addEventListener(
        "message",
        ({ data }) => {
          dispatch(qrInovicePaymentSuccess(data["firebase-messaging-msg-data"].data));
        }
      );
      messaging.onMessage(payload => {
        dispatch(qrInovicePaymentSuccess(payload.data));
      });
    } else {
      const token = 'E0';
      dispatch(qrInvoiceUpdateFirebaseID(token));
    //************************************************************************************************************FIREBASE_____________________________
    }
    return () => dispatch(qrInvoiceResetState());
  },[]);
  
  let paymentSymbol = codigoDePagos;
  if (amount && !employeeNumber) {
    paymentSymbol = `${amount.replace(",", ".")}-${codigoDePagos}`;
  } else if (amount && employeeNumber) {
    paymentSymbol = `${amount.replace(",", ".")}-${codigoDePagos}_${employeeNumber}`;
  } else if (!amount && employeeNumber) {
    paymentSymbol = `${paymentSymbol}_${employeeNumber}`;
  }
  const { expires_in } = props;
  if (toWallet === true) {
    return <Redirect to={{ pathname: '/wallet', expires_in: expires_in }} />;
  } else if (toResultPayment === true) {
    return <Redirect
      to={{
        pathname: '/resultpayment',
        expires_in,
        clientName,
        paymentSymbol,
        currencySymbol,
        amount: amountPaid,
        errorMessage,
        operationType: 'payment'
      }}
    />
  }
  return (
    <div>
      <AutoLogout />
      <MenuBar active='wallet' />
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <img src={icon} className="IconWallet" alt='logo' />
        </div>
      </div>
      <div className="BackgroundAmountInputQR">
        <br />
        <br />
          <div className="ui one column stackable center aligned page grid">
            <div className="column six wide">
            {amount ? (
              <>
                <span className="AmountLegend">{Translate.t('Importe')}</span>
                <br />
                <br />
                <span className="EuroSymbolQR">&euro; </span>
                <span className="AmountToPay">{amount}</span>
              </>
              ) : (
                <>
                  <span className="AmountLegend">{Translate.t('Cliente define importe')}</span>
                  <br />
                  <br />
                </>
              )
            }
            </div>
          </div> 
        <br />
      </div>

      <div className="ui one column stackable center aligned page grid">
        <div className="InvoiceQRContainerWeb">
          <div className="qrFormer">
            <QRCode
              size={200}
              value={paymentSymbol}
            />
          </div>
        </div>
      </div>
      <div className="ui one column stackable center aligned page grid">
        <span className="AmountLegend">
          {paymentSymbol}
        </span>
      </div>
      <div className="buttonGlue">
        <button
          type="button"
          className="PrintQR"
          onClick={ () => window.print() }
        >
          {Translate.t('Imprimir')}
        </button>
        <button
          type="submit"
          className="CancelQR"
          onClick={ () => dispatch(qrInvoiceCancel()) }
        >
          {Translate.t('Cancelar')}
        </button>
      </div>
    </div>
  );
  
}

export default QrInvoice;