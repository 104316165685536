import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import AutoLogout from '../utils/AutoLogout';
import { MenuBar } from '../utils/MenuBar';
import "../static/css/resultPayment.css";
import iconOkOutgoing from '../static/img/Assets/devolver.png';
import iconErrorOutgoing from '../static/img/Assets/error_icon.png';
import iconOkInbound from '../static/img/Assets/empresa_icon.png';
import iconErrorInbound from '../static/img/Assets/error_icon_empresa.png';
import Translate from '../utils/Translate';
import { useDispatch } from 'react-redux';
import { resetToResultPayment } from '../actions';

const ResultPayment = props => {

  const [ toWallet, setToWallet ] = useState(false);
  const dispatch = useDispatch();
  const {
    operationType,
    errorMessage,
    clientNumber,
    amount,
    clientName,
    currencySymbol
  } = props.location;

  useEffect(() => {
    dispatch(resetToResultPayment());
  },[]);

  const showLegend = () => {
    if (errorMessage !== '') {
      return errorMessage;
    } else {
      if (operationType === 'refund') {
        return Translate.t('Reembolso') + `: € ${amount}`;
      } else {
        return `${clientName}: € ${amount}`;
      }
    }
  }

  const renderIcon = () => {
    if (operationType === 'refund') {
      if (errorMessage) {
        return <img src={iconErrorOutgoing} className="IconWallet" alt='logo' />;
      } else {
        return <img src={ iconOkOutgoing } className="IconWallet" alt='logo' />;
      }
    } else {
      if (errorMessage) {
        return <img src={ iconErrorInbound } className="IconWallet" alt='logo' />;
      } else {
        return <img src={ iconOkInbound } className="IconWallet" alt='logo' />;
      }
    }
  }

  if (toWallet === true) {
    return <Redirect
      to={{
        pathname: '/wallet'
      }}
    />
  }
  return (
    <div>
      <AutoLogout />
      <MenuBar active={ operationType === 'refund' ? operationType : 'wallet' } />
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          {renderIcon()}
        </div>
      </div>
      <div className="BackgroundAmountInputQR">
        <br />
        <br />
        <div className="ui one column stackable center aligned page grid">
          <div className="column five wide">
            <span className={ errorMessage !== '' ? 'ResultPaymentError' : 'EuroSymbolQR' }>
              { errorMessage !== '' ? 'ERROR' : Translate.t('Operación exitosa!') }
            </span>
          </div>
        </div>
        <br />
        <div className="ui one column stackable center aligned page grid">
          <div className="column twelve wide">
            <br />
            <span className="AmountToPay">
              {operationType === 'refund' ? (
                <p style={{ marginBottom: "0.75em" }} >{Translate.t('Nro de cliente')}: {clientNumber}</p>
                ) : null }
              {showLegend()}
            </span>
          </div>
        </div>
      </div>
      <div className={errorMessage !== '' ? "BarBackgroundAmountInputErrorQR" : "BarBackgroundAmountInputQR"}>
        <div className="ui one column stackable center aligned page grid">
          {
            errorMessage !== '' ?
            <span className="MessageInsideBarBackgroundErrorMessage">{Translate.t('Consulte con el administrador')}</span>
            :
            <span className="MessageInsideBarBackgroundAmountInputQR">{Translate.t('Operación cobrada')}</span>
          }
        </div>
      </div>
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <button
            type="submit"
            className="GenerateQR"
            onClick={() => setToWallet(true)}
            >
              {Translate.t('OK')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResultPayment;
