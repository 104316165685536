import React, { useState } from 'react';
import { Menu, Table, Loader } from 'semantic-ui-react';
import Translate from './Translate';
import Fecha from 'fecha';
import '../static/css/history.css';

const HistoryTable = ({ transactions, transactionsPerPage, summary, valOpType, txType, loading }) => {

  const [ currentPage, setCurrentPage ] = useState(1);

  const addSymbol = (num, opType = null) => {
    let number;
    if (opType === 'PCOM') {
      number =  `- ${num}`;
    } else if (opType === 'DCOE') {
      number = `- ${num}`;
    } else {
      if (num.toString().indexOf("-") === -1) {
        number = `+ ${num}`;
      } else {
        number = num;
      }
    }
    number = number.indexOf(',') && number.replace(',', '');
    return number;
  }

  const addZeroes = num => {
    let value = Number(num);
    let res = num.toString().split(".");
    if(res.length === 1 || res[1].length < 3)
      value = value.toFixed(2);
    return value;
  }
    
  // Logic for displaying transacciones
  const indexOfLastTodo = currentPage * transactionsPerPage;
  const indexOfFirstTodo = indexOfLastTodo - transactionsPerPage;

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(transactions.length / transactionsPerPage); i++) {
    pageNumbers.push(i);
  }
  if (pageNumbers.length === 0) {
    pageNumbers.push(1);
  }
  
  const renderTable = (data, summary, filter, txType) => {

    if (data.length === 0) {
      return (
        <Table.Row key='0'>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell>{Translate.t('Sin resultados')}</Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
      );
    }

    const currentTodos = data.slice(indexOfFirstTodo, indexOfLastTodo);
    return (
      currentTodos.map((todo , index) => {
        let Importe = 0, NroEmpl;

        let DetalleTransaccion = filter === "<-" ?
          Translate.t('De usuario') + ": " + todo[1].split(filter)[1].split(" E:")[0] :
          Translate.t('A usuario') + ": " + todo[1].split(filter)[1].split(" E:")[0];

        if (filter === "->" && todo[3] !== "DCOM" && todo[3] !== "ENVC") {// REFUNDS(euros)
          Importe = todo[1].split("/")[0].split(":")[1].replace(/\s/g, '');
          Importe = addSymbol(Importe, todo[3]);
        } else {
          Importe = addSymbol(todo[2]);
        }
        NroEmpl = todo[1].split(" E:")[1];
        if (summary === false) {
          if (todo[3] === "PCOM" && filter === "->") {
            Importe = parseFloat(todo[1].split(filter)[0].split(" ")[1].split(":")[1]);
            addZeroes(Importe);
            Importe = addSymbol(Importe);
          }
          if (todo[1].indexOf(filter) !== -1 && todo[3] === txType) {
            return (
              <Table.Row key={index}>
                <Table.Cell>{Fecha.format(new Date(todo[0]), 'DD-MM hh:mm a')}</Table.Cell>
                <Table.Cell>{DetalleTransaccion}</Table.Cell>
                <Table.Cell>{NroEmpl ? NroEmpl : "-"}</Table.Cell>
                <Table.Cell>{Importe} €</Table.Cell>
              </Table.Row>
            );
          }
        } else {
          if (todo[1].indexOf(filter) !== -1 && todo[3] === txType) {
            return (
              <Table.Row key={index}>
                <Table.Cell>{Fecha.format(new Date(todo[0]), 'DD-MM hh:mm a')}</Table.Cell>
                <Table.Cell>{DetalleTransaccion}</Table.Cell>
                <Table.Cell>{NroEmpl ? NroEmpl : "-"}</Table.Cell>
                <Table.Cell>{Importe} €</Table.Cell>
              </Table.Row>
            );
          }
        }
      })
    );
  };

  return (
    <div className="TableContainerTxLog">
      {loading ?
        <Loader active inline='centered' />
        :
        (<Table inverted style={{ backgroundColor: '#005978' }} className="very compact">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{Translate.t('Fecha/hora')}</Table.HeaderCell>
              <Table.HeaderCell>{Translate.t('Operación')}</Table.HeaderCell>
              <Table.HeaderCell style={{ textTransform: 'capitalize' }}>{Translate.t('NroEmpleado')}</Table.HeaderCell>
              <Table.HeaderCell style={{ textTransform: 'capitalize' }}>{Translate.t('Importe')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {renderTable(transactions, summary, valOpType, txType)}
          </Table.Body>
          {window.screen.width >= 1024  &&
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='4'>
                  <Menu floated='right' pagination>
                    {pageNumbers.map(number => {
                      return <Menu.Item
                        key={number}
                        id={number}
                        onClick={event => setCurrentPage(Number(event.target.id))}>
                          {number}
                        </Menu.Item>;
                    })}
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>}
        </Table>)
      }
    </div>
  );
};

export default HistoryTable;