import React, { useEffect } from 'react';
import { Loader, Message } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import { authUserLogin, authUserSetMessage, authLogout } from '../actions';
import '../static/css/login.css';
import logo from '../static/img/Assets/Isologo_login.png';
import underline from '../static/img/Assets/Barra_Verde.png';
import Translate from '../utils/Translate';
import { useForm } from 'react-hook-form';

const Login = (props) => {

  const {
    errorMessage,
    loading,
    toWallet,
    expires_in,
    token
  } = useSelector( state => state.auth );
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    const { location } = props;
    let StringFromUrl = location.pathname.substring(0);
    if (StringFromUrl) {
      StringFromUrl = StringFromUrl.substring(1, StringFromUrl.length);
      const sessionExpired = qs.parse(StringFromUrl);
      console.log(sessionExpired)
      dispatch(authUserSetMessage(sessionExpired));
    }
    // dispatch(authLogout());
  },[]);

  const renderButton = () => {
    if (loading) {
      return <div className="loaderContainer"><Loader active /></div>;
    }
    
    return (
      <button
        type="submit"
        className="DoLogin"
      >
        {Translate.t('Acceder')}
      </button>
    );
  }

  const onSubmit = formValues => {
    dispatch(authUserLogin(formValues));
  }
  
  if (toWallet === true) {
    return <Redirect
      to={{
        token,
        expires_in,
        pathname: '/wallet'
      }}
    />;        
  }

  return (
    <React.Fragment>
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <img className="LogoLogin" src={logo} alt="logo" />
          <div>
            <span className="TitleLogin" alt="logo">{Translate.t('Accede a tu cuenta')}</span>
          </div>
          <div>
            <img className="UnderlineLogin" src={underline} alt="underline" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="ui form error">
            <div className={`InputUser ${errors.clientNumber && "errorField"}`} >
              <input
                name="clientNumber"
                placeholder={Translate.t('NRO DE CLIENTE')}
                type="number"
                className="InputUser"
                ref={register({ required: true })}
              />
            </div>
            {errors.clientNumber?.type === 'required' && 
              <div className="errorMessage">
                {Translate.t('Nro de cliente no debe estar vacío')}
              </div>
            }
            <div className={`PassContainer ${errors.passwd && "errorField"}`}>
              <input 
                name="passwd"
                type="password"
                className="InputUser"
                placeholder={Translate.t('CONTRASEÑA')}
                ref={register({ required: true, minLength: 8 })}  
              />
            </div>
            {errors.passwd?.type === "required" && 
              <div className="errorMessage">
                {Translate.t('Campo clave no debe estar vacío')}
              </div>
            }
            {errors.passwd?.type === "minLength" && 
              <div className="errorMessage">
                {Translate.t('La clave debe contener 8 o mas caracteres')}
              </div>
            }
            {renderButton()}
          </form>
        </div>
      </div>
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <br />
          <Message compact negative hidden={!errorMessage}>
            <Message.Header>Error</Message.Header>
            <p>{errorMessage}.</p>
          </Message>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
