import React, { Component } from 'react';
import { Message, Input, Icon } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { findDOMNode } from 'react-dom';
import AutoLogout from '../utils/AutoLogout';
import { MenuBar } from '../utils/MenuBar';
import Translate from '../utils/Translate';

class UserValidation extends Component {

  state = {
    toBackoffice: 'false',
    code: '',
    phoneNumber: '',
    mbId: '',
    errorMessage: '',
    phoneErrorMessage: '',
    codeErrorMessage: ''
  };

  componentDidMount() {

      axios.post(`/totp-secret`).then(res => {
        const secret = res.data.secret;
        console.log(secret);

        axios.post(`/totp-generate`, { secret }).then(res => {
          const code = res.data.token;
          if (code) {
            this.setState({ code });
          }
          console.log(code);

        }).catch(err => {
          console.log(err);
        });

      }).catch(err => {
        console.log(err);
      });

  }

  onButtonClick = () => {
    findDOMNode(this.El).style.backgroundColor = '#ff8c00';
    findDOMNode(this.El).style.borderColor = '#ff8c00';
  }
  onVerifyClick() {
    axios.post(`/totp-validate`, { token: this.state.code }).then(res => {
      res.data.valid ? this.setState({ toBackoffice: true }) : this.setState({ errorMessage: 'Code verification failed.' });
    }).catch(err => {
      console.log(err);
    });
  }

  onSmsSubmit = (event) => {
    event.preventDefault();
    if (this.state.phoneNumber !== '') {
      axios.post(`/sendsms`, { pin: this.state.code, mobileNumber: this.state.phoneNumber }).then(res => {
        console.log(res);

      }).catch(err => {
        console.log(err);
      });
    } else {

    }
  }

  onCodeSubmit = (event) => {
    event.preventDefault();
  }

  render() {
    if (this.state.toBackoffice === true) {
      return <Redirect
        to={{
          pathname: '/backoffice'
        }}
      />
    }
    return (
      <div>
        <AutoLogout />
        <MenuBar active='backoffice' />
        <div className="ui one column stackable center aligned page grid">
            <div className="column seven wide">
                <Message info>
                    <Message.Content>
                      <Message.Header>
                        Por razones de seguridad, necesitamos confirmar que eres realmente tú.
                      </Message.Header>
                      <form onSubmit={this.onSmsSubmit}>
                        <br />
                        <p>
                          Ingresa tu móvil para enviarte la clave por SMS:
                        </p>
                        <Input
                          type="text"
                          size="large"
                          className="Amountinput"
                          onChange={(e) => { this.setState({ phoneNumber: e.target.value }) }}
                        />
                        <br />
                        <Message compact negative hidden={!this.state.phoneErrorMessage}>
                          {this.state.phoneErrorMessage}.
                        </Message>
                        <p>
                          <button
                            type="submit"
                            className="BtnTotp"
                            ref={(El) => { this.El = El; }}
                            onClick={this.onButtonClick}
                            >
                              Enviar
                          </button>
                        </p>
                      </form>
                      <form onSubmit={this.onCodeSubmit}>
                        <br />
                        <p>
                          Ingresa el código enviado a tu móvil por SMS:
                        </p>
                        <Input
                          type="text"
                          size="large"
                          className="Amountinput"
                          onChange={(e) => { this.setState({ code: e.target.value }) }}
                        />
                        <br />
                        <Message compact negative hidden={!this.state.codeErrorMessage}>
                          {this.state.codeErrorMessage}.
                        </Message>
                        <p>
                          <button
                            type="button"
                            className="BtnTotp"
                            onClick={this.onVerifyClick}
                            >
                              Validar
                          </button>
                      </p>
                      <br />
                    </form>
                    </Message.Content>
                </Message>
            </div>
          </div>
      </div>
    );
  }
}
export default UserValidation;
