import {
  QRINVOICE_CANCEL_CHARGE,
  QRINVOICE_ERROR,
  QRINVOICE_RESET_STATE,
  QRINVOICE_PAYMENT_SUCCESS,
  QRINVOICE_RESET_TO_RESULT_PAYMAENT
} from '../actions/types';

const INITIAL_STATE = {
  errorMessage: '',
  toWallet: false,
  toResultPayment: false,
  // currency: sessionStorage.getItem('currency'),
  clientName: '',
  currencySymbol: '',
  amountPaid: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QRINVOICE_RESET_STATE:
      return { ...state, ...INITIAL_STATE };
    case QRINVOICE_ERROR:
      return { ...state, errorMessage: action.payload };
    case QRINVOICE_CANCEL_CHARGE:
      return { ...state, toWallet: true, errorMessage: '' };
    case QRINVOICE_PAYMENT_SUCCESS:
      const { nombre, importe, simbolo } = action.payload;
      return {
        ...state,
        toResultPayment: true,
        clientName: nombre,
        amountPaid: importe,
        currencySymbol: simbolo
      };
    case QRINVOICE_RESET_TO_RESULT_PAYMAENT:
      return { ...state, toResultPayment: false };
    default:
      return state;
  }
};