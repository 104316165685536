import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detectBrowserLanguage from 'detect-browser-language';
import translationEN from '../static/locales/en/translation';
import translationES from '../static/locales/es/translation';

const browserLang = detectBrowserLanguage().split("-");
const resources = {
  en: { translation: translationEN },
  es: { translation: translationES }
};
// initialize i18next with catalog and language to use
i18n.use(initReactI18next).init({
  resources,
  lng: browserLang[0]
});
export default i18n;
