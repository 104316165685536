import React from 'react';
import Translate from './Translate';

const Spinner = (props) => {
  return (
    <div className="ui active dimmer">
      <div className="ui text loader">
        {props.text}
      </div>
    </div>
  );
};

Spinner.defaultProps = {
  text: Translate.t('Cargando...')
};
export default Spinner;
