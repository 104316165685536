import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MenuBar } from '../utils';
import AutoLogout from '../utils/AutoLogout';
import Translate from '../utils/Translate';
import SelectCurrency from '../utils/SelectCurrency';
import { getCurrencies, changeCurrency } from '../actions'
import '../static/css/wallet.css';
import icon from '../static/img/Assets/coin.png';

const SetCurrency = (props) => { 
  
  const { currenciesList, errorMessage, loading } = useSelector( state => state.setCurrency );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrencies());
  },[]);

  const handleChange = event => {
    const currencyVal = event.target.textContent;
    dispatch(changeCurrency(currencyVal));
  }
  

  return (
    <div className="container">
      <MenuBar
        active='set_currency'
      />
      <AutoLogout expires_in={props.location.expires_in} />
      <div className="WalletContainer">
        <div className="ui one column stackable center aligned page grid">
          <div className="column twelve wide">
            <img src={icon} className="IconWallet" alt='logo' />
          </div>
        </div>
        <div className="BackgroundAmountInput">
          <br />
          <br />
          <div className="ui one column stackable center aligned page grid">
            <div className="column sixteen wide">
              <span className="EnterAmountLegend">{Translate.t('Seleccionar moneda para cobros')}</span>
            </div>
            <div className="ui one column stackable centered aligned page grid">
              <div className="column five wide">
                <div style={{ maxWidth: '180px', minWidth: '120px', margin: "0 auto" }} >
                  {!loading ? (
                    <SelectCurrency onChange={handleChange} data={currenciesList} />
                    ): Translate.t('Cargando...')
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  

};

// const mapStateToProps = ({ setCurrency }) => {
//   return { setCurrency }
// };

export default SetCurrency;
