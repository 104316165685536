import React from 'react';
import { useSelector } from 'react-redux';
import { MenuBar } from '../utils';
import AutoLogout from '../utils/AutoLogout';
import '../static/css/login.css';
import Translate from '../utils/Translate';
import global from '../global';

const baseUrl = global.url;

const LivePayments = (props) => {
  const { Id, Pwd2 } = useSelector(state => state.auth.userData);

  return (
    <div>
      <MenuBar active="pagos_rt" />
      <AutoLogout expires_in={props.location.expires_in} />
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <div style={{ marginTop: '30px' }}>
            <form
              target="_blank"
              method="post"
              action={`${baseUrl}/public/dynamics/EntradaPagos.aspx`}
            >
              <input type="hidden" name="user" value={Id} />
              <input type="hidden" name="pwd" value={Pwd2} />
              <h2 style={{ color: 'white'}}>{Translate.t('Confirma para navegar a otro sitio?')}</h2>
              <input type="submit" value={Translate.t('Confirmar')} className="DoLogin" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LivePayments;

