import {
  CRYPTOSPRICE_GET_CURRENCIES,
  CRYPTOSPRICE_GET_CURRENCIES_SUCCESS,
  CRYPTOSPRICE_GET_CURRENCIES_ERROR
} from './types';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';

export const getCryptosPrice = () => async dispatch => {

  const token = sessionStorage.getItem('Token');
  if (token) {
    dispatch({ type: CRYPTOSPRICE_GET_CURRENCIES });
    const params = {
      'url': `/extapi/Logged/CryptoRates`,
      'method': 'GET',
      'headers': {
        'content-type': 'application/json;charset=utf-8',
        'authorization': `Bearer ${token}`
      }
    };
    try {
      const res = await BackOfficeEndpoints(params);
      dispatch({ type: CRYPTOSPRICE_GET_CURRENCIES_SUCCESS, payload: res.data });
    } catch (err) {
        dispatch({ type: CRYPTOSPRICE_GET_CURRENCIES_ERROR, payload: err.toString() });
    }
  }
};