import {
  REFUND_MAKE_REFUND,
  REFUND_MAKE_REFUND_SUCCESS,
  REFUND_MAKE_REFUND_ERROR,
  REFUND_RESET_STATE,
  REFUND_RESET_TORESULTPAYMENT
} from '../actions/types';

const INITIAL_STATE = {
  amount: 0,
  clientNumber: '',
  loading: false,
  errorMessage:'',
  toResultPayment: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFUND_RESET_STATE:
      return { ...state, ...INITIAL_STATE };
    case REFUND_MAKE_REFUND:
      return { ...state, loading: true, errorMessage: '' };
    case REFUND_MAKE_REFUND_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        errorMessage: '', 
        toResultPayment: true, 
        amount: action.payload.amountToRefund, 
        clientNumber: action.payload.toClientNumber 
      };
    case REFUND_MAKE_REFUND_ERROR:
      return { ...state, loading: false, errorMessage: action.payload };
    case REFUND_RESET_TORESULTPAYMENT:
      return { ...state, toResultPayment: false }
    default:
      return state;
  }
};