import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const validToken = sessionStorage.getItem('Token');

  return (
    <Route
      {...rest}
      render={
        props => validToken ? (<Component {...props} />) : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
      }
    />
  )
};

export default PrivateRoute;
