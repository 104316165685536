import React from 'react';
import { Button, Popup, Divider } from 'semantic-ui-react';
import Translate from './Translate';
import '../static/css/popup.css';

const ProfilePopUp = (props) => {
  const { nombre, email, idCliente } = props;
  return (
    <Popup style={{backgroundColor: '#1f88be', border: '1px solid #1f88be'}}
      trigger={<Button circular className='Option' color='linkedin' icon='user circle outline'></Button>}
      content={
        <div>
          <div className="labelInfoUser">{Translate.t('NOMBRE')}</div>
          <span className="infouser">{nombre || sessionStorage.getItem('nombre')}</span>
          <Divider/>
          <div className="labelInfoUser">E-MAIL</div>
          <span className="infouser">{email || sessionStorage.getItem('email')}</span>
          <Divider/>
          <div className="labelInfoUser">{Translate.t('NRO DE CLIENTE')}</div>
          <span className="infouser">{idCliente || sessionStorage.getItem('idCliente')}</span>
          <Divider/>
          <a href="/" className="logOut">{Translate.t('Salir')}</a>
        </div>
        }
      on='click'
      position='bottom right'
    />
  );
}
export { ProfilePopUp };
