import {
  HISTORY_GET_DATA,
  HISTORY_GET_DATA_SUCCESS,
  HISTORY_GET_DATA_ERROR,
  SET_TRANSACTIONS_PER_PAGE,
  SET_TRANSACTIONS_PARAMETERS,
  HISTORY_RESET_STATE
} from './types';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';
import Translate from '../utils/Translate';


export const getData = (url, dateTo=null, dateFrom=null, requestType='GET') => async (dispatch, getState) => {
  const { valOpType, txType } = getState().history;
  dispatch({ type: HISTORY_GET_DATA });
  const params = {
    'method': requestType,
    headers: {
      'content-type': 'application/json;charset=utf-8',
      'authorization': `Bearer ${sessionStorage.getItem('Token')}`
    },
    'url': url
  };
  if (requestType === 'POST') {
    params.data = {
      'DesdeFecha': dateFrom,
      'HastaFecha': dateTo
    };
  }
  try {
    const response = await BackOfficeEndpoints(params);

    if (response.data.data) {
      let a = 0;
      let arrFila = [];
      response.data.data.map( (objeto, i) => {
        if (objeto.info_adicional.indexOf(valOpType) !== -1 && objeto.tipo_operacion.indexOf(txType) !== -1) {
          arrFila[a] = [
            objeto.fecha,
            objeto.info_adicional,
            objeto.info_adicional.split('/')[0].split(':')[1],
            objeto.tipo_operacion
          ];
          a++;
        }
      });
      dispatch({ type: HISTORY_GET_DATA_SUCCESS, payload: arrFila });
    } else if (response.status === 401) {
        const errorMessage = Translate.t('Sesion expirada. Por favor inicie sesion nuevamente')
        dispatch({ type: HISTORY_GET_DATA_ERROR, payload: errorMessage });
    }
  } catch(err) {
  }
}

export const setTransactionsPerPage = transactionsPerPage => ({ type: SET_TRANSACTIONS_PER_PAGE, payload: transactionsPerPage });

export const setTransactionsParameters = (valOpType, txType, summary = null) => {
  const parameters = { valOpType, txType, summary };
  return { type: SET_TRANSACTIONS_PARAMETERS, payload: parameters }
}

export const resetState = () => ({ type: HISTORY_RESET_STATE });