import { 
  WALLET_GET_USER_DATA,
  WALLET_SET_USER_DATA,
  WALLET_GOT_ERROR,
  WALLET_GO_QR_SCREEN,
  WALLET_RESET_STATE,
  WALLET_RESET_TO_QR_GENERATOR,
  WALLET_QR_WITHOUT_AMOUNT
} from './types';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';
import Translate from '../utils/Translate';

export const walletError = error => {
  return {type: WALLET_GOT_ERROR, payload: error };
}

export const walletResetState = () => dispatch => {
  dispatch({ type: WALLET_RESET_STATE });
};

export const walletResetToQrInvoice = () => {
  return { type: WALLET_RESET_TO_QR_GENERATOR };
};

export const walletGetUserData = () => async dispatch => {
  
  const token = sessionStorage.getItem('Token');
  if (token) {
    dispatch({ type: WALLET_GET_USER_DATA });
    const params = {
      'method': 'GET',
      headers: {
        'content-type': 'application/json;charset=utf-8',
        'authorization': `Bearer ${token}`
      },
      'url': `/extapi/Logged/Profile`
    };
    try {
      const response = await BackOfficeEndpoints(params);
      const info = response.data.data;
      if (!sessionStorage.getItem('nombre')) {
        sessionStorage.setItem('nombre', info.Nombre);
        sessionStorage.setItem('email', info.Email);
        sessionStorage.setItem('idCliente', info.Id);
        sessionStorage.setItem('codigoDePagos', info.CodigoParaEnvios);
        sessionStorage.setItem('FirebaseId', info.FirebaseId);
      }
      const { CodigoParaEnvios, Id, Nombre, Email } = info;
      dispatch({ type: WALLET_SET_USER_DATA, payload: { CodigoParaEnvios, Id, Nombre, Email } });
    } catch (err) {
      if (err.message === "Network Error") {
        walletGotError(dispatch, Translate.t('Compruebe su conexión a internet'));
      } else if (err.response.status === 401) {
        walletGotError(dispatch, Translate.t('Sesion expirada. Por favor inicie sesion nuevamente'));
      }
    }
  }
};

export const walletGenQR = formValues => async dispatch => {
  const { AmountInput, employeeNumber, check } = formValues;

  if(!check) {
    let amountFormatted = AmountInput.indexOf(".") === -1 ? 
      AmountInput.concat(",00") : AmountInput.replace(".", ",");
  
    amountFormatted = amountFormatted.substr(amountFormatted.length - 1) === "," ? 
      amountFormatted.concat("00") : amountFormatted;
    sessionStorage.setItem('amount', amountFormatted);
    dispatch({
      type: WALLET_GO_QR_SCREEN,
      payload: {
        amountFormatted,
        employeeNumber: employeeNumber !== null ? employeeNumber : ''
      }
    });
  } else {
    sessionStorage.removeItem('amount');
    dispatch({ type: WALLET_GO_QR_SCREEN, payload: { employeeNumber } });
  }
};

const walletGotError = (dispatch, message) => {
  dispatch({ type: WALLET_GOT_ERROR, payload: message });
};

export const walletQrWithoutAmount = () => ({ type: WALLET_QR_WITHOUT_AMOUNT }); 