import React, { useEffect } from 'react';
import { Message, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { getCryptosPrice } from '../actions';
import { MenuBar } from '../utils';
import CryptoList from '../utils/CryptoList';
import AutoLogout from '../utils/AutoLogout';
import '../static/css/cryptos_price.css';
import icon from '../static/img/Assets/Cripto_price_icon.png';
import Translate from '../utils/Translate';

const CryptosPrice = () => {

  const {
    errorMessage,
    priceList,
    loading
  } = useSelector(state => state.cryptosPrice);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getCryptosPrice());
  },[]);

  const renderCryptoList = () => {
    if (loading) {
      return <div className="loaderContainer"><Loader active /></div>;
    }
    return <CryptoList priceList={priceList} />;
  }

  return (
    <div>
      <AutoLogout />
      <MenuBar active='cryptos_price' />
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <img src={icon} className="IconWallet" alt='logo' />
        </div>
      </div>
      <div className="BackgroundAmountInputQR">
        <br />
        <br />
        <div className="ui one column stackable center aligned page grid">
          <div className="column twelve wide">
            <span className="TitleScreen">{Translate.t('Cotización Criptomonedas')}</span>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <Message compact negative hidden={!errorMessage}>
            <Message.Header>Error</Message.Header>
            <p>{errorMessage}.</p>
          </Message>
        </div>
      </div>
      <div className="TableContainerCryptosPrice">
        {renderCryptoList()}
      </div>
      <br />
    </div>
  );
  
}

export default CryptosPrice;
