import {
  CRYPTOSPRICE_GET_CURRENCIES,
  CRYPTOSPRICE_GET_CURRENCIES_SUCCESS,
  CRYPTOSPRICE_GET_CURRENCIES_ERROR
} from '../actions/types';

const INITIAL_STATE = {
  errorMessage: '',
  priceList: [],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CRYPTOSPRICE_GET_CURRENCIES:
      return { ...state, loading: true, errorMessage: '' }
    case CRYPTOSPRICE_GET_CURRENCIES_SUCCESS:
      return { ...state, loading: false, errorMessage: '', priceList: action.payload.data };
    case CRYPTOSPRICE_GET_CURRENCIES_ERROR:
      return { ...state, loading: false, errorMessage: action.payload };
    default:
      return state;
  }
};