import React from 'react';
import { Table } from 'semantic-ui-react'
import Translate from './Translate';
import BTCIcon from '../static/img/Assets/btc.png';
import ETHIcon from '../static/img/Assets/eth.png';
import XRPIcon from '../static/img/Assets/xrp.png';
import ADAIcon from '../static/img/Assets/ada.png';
import BNBIcon from '../static/img/Assets/bnb.png';
import BCHIcon from '../static/img/Assets/bch.png';
import DASHIcon from '../static/img/Assets/dash.png';
import EOSIcon from '../static/img/Assets/eos.png';
import IOTIcon from '../static/img/Assets/iot.png';
import LTCIcon from '../static/img/Assets/ltc.png';
import XMRIcon from '../static/img/Assets/xmr.png';
import NANOIcon from '../static/img/Assets/nano.png';
import XEMIcon from '../static/img/Assets/xem.png';
import NEOIcon from '../static/img/Assets/neo.png';
import QTUMIcon from '../static/img/Assets/qtum.png';
import SCIcon from '../static/img/Assets/sc.png';
import STEEMIcon from '../static/img/Assets/steem.png';
import XLMIcon from '../static/img/Assets/xlm.png';
import TRXIcon from '../static/img/Assets/trx.png';
import CultonIcon from '../static/img/Assets/cultonLogo.png';
import ClinkIcon from '../static/img/Assets/chainlink.png';
import ThetaIcon from '../static/img/Assets/theta.png';
import VechainIcon from '../static/img/Assets/vechain.png';
import UniswapIcon from '../static/img/Assets/unisw.png';
import YearnIcon from '../static/img/Assets/yearm.png';
import ThorIcon from '../static/img/Assets/Asgard-finance_THOR.png';
import AaveIcon from '../static/img/Assets/Aave_AAVE.png';
import AalgoIcon from '../static/img/Assets/Algorand_ALGO.png';
import AntIcon from '../static/img/Assets/Aragon_ANT.png';
import OneIcon from '../static/img/Assets/Harmony_ONE.png';
import AvaxIcon from '../static/img/Assets/Avalanche_AVAX.png';
import AtomIcon from '../static/img/Assets/Cosmos_ATOM.png';
import SolIcon from '../static/img/Assets/Solana_SOL.png';
import SafemoonIcon from '../static/img/Assets/SafeMoon_SAFEMOON.png';
import MaticIcon from '../static/img/Assets/Polygon_MATIC.png';
import ManaIcon from '../static/img/Assets/Decentraland_MANA.png';
import PolkadotIcon from '../static/img/Assets/Polkadot_DOT.png';
import LunaIcon  from '../static/img/Assets/Terra_LUNA.png';
import CakeIcon from '../static/img/Assets/PancakeSwap_CAKE.png';
import EnjinIcon from '../static/img/Assets/Enjin-Coin_ENJ.png';
import ChzIcon from '../static/img/Assets/Chiliz_CHZ.png';
import DogeIcon from '../static/img/Assets/Dogecoin_DOGE.png';
import ECTEIcon from '../static/img/Assets/ECT_gris.png';
import ECTEHIcon from '../static/img/Assets/ECT_fucsia.png';
import ECTEGIcon from '../static/img/Assets/ECT_naranja.png';

const CryptoList = props => {

  return (
    <Table inverted style={{ backgroundColor: '#005978' }} className="very compact">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>{Translate.t('Moneda')}</Table.HeaderCell>
          <Table.HeaderCell>{Translate.t('Precio')}</Table.HeaderCell>
          <Table.HeaderCell>{Translate.t('Cambio (24h)')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        { props.priceList.map( ({ moneda, cotiz_actual, cotiz_antigua }) => {
          let cotizAntigua = parseFloat(((cotiz_actual - cotiz_antigua)  / cotiz_antigua) * 100).toFixed(2);
          return (
            <Table.Row key={moneda}>
              <Table.Cell><img style={{ height: '24px', width: '24px' }} src={getIcon(moneda)} alt={moneda} /></Table.Cell>
              <Table.Cell>{moneda}</Table.Cell>
              <Table.Cell>€ {parseFloat(cotiz_actual).toFixed(2)}</Table.Cell>
              <Table.Cell>{cotizAntigua.indexOf("-") === -1 ? "+" + cotizAntigua : cotizAntigua}%</Table.Cell>
            </Table.Row>
          );
        } ) }
      </Table.Body>
    </Table>
  );
};

const getIcon = name => {
  switch (name) {
    case 'BTC':
      return BTCIcon;
    case 'BCH':
      return BCHIcon;
    case 'DASH':
      return DASHIcon;
    case 'ETH':
      return ETHIcon;
    case 'IOT':
      return IOTIcon;
    case 'LTC':
      return LTCIcon;
    case 'NEO':
      return NEOIcon;
    case 'XMR':
      return XMRIcon;
    case 'XRP':
      return XRPIcon;
    case 'ADA':
      return ADAIcon;
    case 'XEM':
      return XEMIcon;
    case 'TRX':
      return TRXIcon;
    case 'SC':
      return SCIcon;
    case 'XLM':
      return XLMIcon;
    case 'QTUM':
      return QTUMIcon;
    case 'BNB':
      return  BNBIcon;
    case 'EOS':
      return EOSIcon;
    case 'NANO':
      return NANOIcon;
    case 'ECTE':
      return ECTEIcon;
    case 'ECTE CASH':
      return ECTEHIcon;
    case 'ECTE GAS':
      return ECTEGIcon;
    case 'CULTON':
      return CultonIcon;
    case 'LINK':
      return ClinkIcon;
    case 'THETA':
      return ThetaIcon;
    case 'VET':
      return VechainIcon;
    case 'UNI':
      return UniswapIcon;
    case 'YFI':
      return YearnIcon;
    case 'AAVE':
      return AaveIcon;
    case 'ALGO':
      return AalgoIcon;
    case 'ANT':
      return AntIcon;
    case 'ATOM':
      return AtomIcon;
    case 'AVAX':
      return AvaxIcon;
    case 'CAKE':
      return CakeIcon;
    case 'CHZ':
      return ChzIcon;
    case 'DOGE':
      return DogeIcon;
    case 'DOT':
      return PolkadotIcon;
    case 'ENJ':
      return EnjinIcon;
    case 'LUNA':
      return LunaIcon;
    case 'MANA':
      return ManaIcon;
    case 'MATIC':
      return MaticIcon;
    case 'ONE':
      return OneIcon;
    case 'SAFEMOON':
      return SafemoonIcon;
    case 'SOL':
      return SolIcon;
    case 'THOR':
      return ThorIcon;
    default:
      return STEEMIcon;
  }
};
export default CryptoList;
