import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import detectBrowserLanguage from 'detect-browser-language';
import Spinner from './Spinner';
import Translate from './Translate';
import iconYou from '../static/img/Assets/vector_smart_object.png';
import iconStores from '../static/img/Assets/map_poi_small.png';
import imgInfoWindowYou from '../static/img/Assets/logo_cliente_menulateral.png';
import imgInfoWindowStores from '../static/img/Assets/logo_empresa_menulateral.png';
import { getStores, getMyPosition, getInfoMarker } from '../actions'

const Maps = (props) => {

  const { 
    storesList, 
    errorMessage, 
    latitude, 
    longitude, 
    errorMessageGeo, 
    selectedPlace, 
    activeMarker } = useSelector(state => state.stores);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStores());
    dispatch(getMyPosition());
  },[]);

  const onMarkerClick = (props, marker, e) => {
    dispatch(getInfoMarker(props, marker));
  }

  const strip_html_tags = (html) => {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const renderStoresLocation = () => {
    return storesList.map((store, index) => {
      const { Nombre, Descripcion, GpsLatitud, GpsLongitud } = store;
      return (
        <Marker
          key={index}
          onClick={onMarkerClick}
          icon={{ url: iconStores }}
          title={strip_html_tags(Nombre)}
          img={imgInfoWindowStores}
          name={strip_html_tags(Descripcion)}
          position={{lat: GpsLatitud, lng: GpsLongitud}}
        />
      );
    });
  }

  const renderMyLocation = () => {
    if(!errorMessageGeo && latitude) {
      return (
        <Marker
          onClick={onMarkerClick}
          icon={{ url: iconYou }}
          title={Translate.t('Tu ubicación')}
          name={Translate.t('Aquí estás tú')}
          img={imgInfoWindowYou}
          position={{lat: latitude, lng: longitude}}
          // position={{lat: 42.589451, lng: -5.613526}} //eurocoin oficinas
        />
      );
    }
    if (errorMessageGeo && !latitude) {
      return <h3>Error: {errorMessageGeo}</h3>;
    }
    return <Spinner />;
  }
  
  if (!props.google) {
    return <Spinner />;
  }
  return (
    <Map
      style={{boxShadow: '4px 4px 8px', width: '92%' }}
      google={props.google}
      zoom={13}
      center={{
        lat: latitude,
        lng: longitude
        // lat: 42.6036043, //Leon
        // lng: -5.5949009
      }}
    >
      {renderMyLocation()}
      {renderStoresLocation()}
      <InfoWindow
        marker={activeMarker}
        visible={true}
      >
        <div>
          {selectedPlace && <img src={selectedPlace.img} alt='storeImg'/>}
          {selectedPlace && <h2>{selectedPlace.title}</h2>}
          {selectedPlace && <h4>{selectedPlace.name}</h4>}
        </div>
      </InfoWindow>
    </Map>
  );

}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyBRLGjZtNnyauxO64_LLqL16CT2baY39Ws'),
  language: detectBrowserLanguage().split("-")[0]
})(Maps);
