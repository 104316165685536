import React, { Component } from 'react';

class NotFound extends Component {

  render() {
    return (
      <h2>404 Not Found!</h2>
    );
  }
}
export default NotFound;
