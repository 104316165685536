import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_ERROR,
  CHANGE_CURRENCY
} from './types';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';
import Translate from '../utils/Translate';

export const getCurrencies = () => async (dispatch) => {
  dispatch({ type: GET_CURRENCIES });

  const params = {
    'method': 'GET',
    headers: {
      'content-type': 'application/json;charset=utf-8',
      'authorization': `Bearer ${sessionStorage.getItem('Token')}`
    },
    'url': `/extapi/Logged/GetAvailableCoinsForReceivingPayment`
  };

  try {
    const resp = await BackOfficeEndpoints(params).catch( err => {
      if (err.response.status === 401) {
        const errorMessage = Translate.t('Sesion expirada. Por favor inicie sesion nuevamente');
        dispatch({ type: GET_CURRENCIES_ERROR, payload: errorMessage });
      }
    });
    dispatch({ type: GET_CURRENCIES_SUCCESS, payload: resp.data.data });
  } catch (err) {
    if(err.response.status !== 401){
      dispatch({ type: GET_CURRENCIES_ERROR, payload: err.response });
    }
  }
};

export const changeCurrency = ( currencyVal ) => async ( dispatch ) => {
  sessionStorage.removeItem('currency');
  const params = {
    'method': 'POST',
    headers: {
      'content-type': 'application/json;charset=utf-8',
      'authorization': `Bearer ${sessionStorage.getItem('Token')}`
    },
    data: { PaymentReceivedCoin: currencyVal },
    'url': `/extapi/Logged/UpdatePaymentReceivedCoin`
  };
  try {
    const resp = await BackOfficeEndpoints(params).catch(err => {
      if (err.response.status === 401) {
        // this.setState({ errorMessage: Translate.t('Sesion expirada. Por favor inicie sesion nuevamente') });
      }
    });
    sessionStorage.setItem('currency', currencyVal);
    dispatch({ type: CHANGE_CURRENCY, payload: currencyVal });
  } catch (err) {
  }
}