import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { MenuDrawer } from './MenuDrawer';
import '../static/css/popup.css';

const MenuPopUp = (props) => {
  const [ show, setShow ] = useState(true);
  
  return (
    <div>
      <div className="HamburgerContainer">
        <Button color='linkedin' icon='sidebar' onClick={() => setShow(!show)} />
      </div>
      <div style={{ position: 'absolute', 'width': '60%', maxWidth: '400px' }}>
        <MenuDrawer active={props.active} hidden={show} />
      </div>
    </div>
  );
};

export default MenuPopUp;
