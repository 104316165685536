import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import BTCIcon from '../static/img/Assets/ico_currencies/btc.png';
import ETHIcon from '../static/img/Assets/ico_currencies/eth.png';
import XRPIcon from '../static/img/Assets/ico_currencies/xrp.png';
import ADAIcon from '../static/img/Assets/ico_currencies/ada.png';
import BNBIcon from '../static/img/Assets/ico_currencies/bnb.png';
import BCHIcon from '../static/img/Assets/ico_currencies/bch.png';
import DASHIcon from '../static/img/Assets/ico_currencies/dash.png';
import EOSIcon from '../static/img/Assets/ico_currencies/eos.png';
import IOTIcon from '../static/img/Assets/ico_currencies/iot.png';
import LTCIcon from '../static/img/Assets/ico_currencies/ltc.png';
import XMRIcon from '../static/img/Assets/ico_currencies/xmr.png';
import NANOIcon from '../static/img/Assets/ico_currencies/nano.png';
import XEMIcon from '../static/img/Assets/ico_currencies/xem.png';
import NEOIcon from '../static/img/Assets/ico_currencies/neo.png';
import QTUMIcon from '../static/img/Assets/ico_currencies/qtum.png';
import SCIcon from '../static/img/Assets/ico_currencies/sc.png';
import STEEMIcon from '../static/img/Assets/ico_currencies/steem.png';
import XLMIcon from '../static/img/Assets/ico_currencies/xlm.png';
import TRXIcon from '../static/img/Assets/ico_currencies/trx.png';
import ECTEIcon from '../static/img/Assets/ico_currencies/ECT_gris.png';
import ECTEHIcon from '../static/img/Assets/ico_currencies/ECT_fucsia.png';
import ECTEGIcon from '../static/img/Assets/ico_currencies/ECT_naranja.png';
import EUROIcon from '../static/img/Assets/ico_currencies/euro.png';

const SelectCurrency = (props) => (
  <Dropdown
    placeholder='ECTE Cash'
    options={formattedOptions(props.data)}
    item
    fluid selection
    onChange={props.onChange}
  />
);

const formattedOptions = (options=[]) => {
  return (
    options.map(option => {
      return {
        key: option.IdMoneda,
        value: option.IdMoneda,
        text: option.IdMoneda,
        image: {
          avatar: true,
          src: getIcon(option.IdMoneda)
        }
      };
    })
  );
};

const getIcon = (Name) => {
  switch(Name) {
    case 'BTC':
      return BTCIcon;
    case 'BCH':
      return BCHIcon;
    case 'DASH':
      return DASHIcon;
    case 'ETH':
      return ETHIcon;
    case 'IOT':
      return IOTIcon;
    case 'LTC':
      return LTCIcon;
    case 'NEO':
      return NEOIcon;
    case 'XMR':
      return XMRIcon;
    case 'XRP':
      return XRPIcon;
    case 'ADA':
      return ADAIcon;
    case 'XEM':
      return XEMIcon;
    case 'TRX':
      return TRXIcon;
    case 'SC':
      return SCIcon;
    case 'XLM':
      return XLMIcon;
    case 'QTUM':
      return QTUMIcon;
    case 'BNB':
      return  BNBIcon;
    case 'EOS':
      return EOSIcon;
    case 'NANO':
      return NANOIcon;
    case 'ECTE':
      return ECTEIcon;
    case 'ECTE CASH':
      return ECTEHIcon;
    case 'ECTE GAS':
      return ECTEGIcon;
    case 'EUR':
      return EUROIcon;
    default:
      return STEEMIcon;
  }
};

export default SelectCurrency;
