import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Dropdown, Message } from 'semantic-ui-react';
import Translate from './Translate';
import HistoryTable from './HistoryTable';
import {
  getData,
  setTransactionsPerPage,
  setTransactionsParameters,
  resetState
} from '../actions';

const Paginator = () => {
  
  const {
    transactions,
    errorMessage,
    transactionsPerPage,
    valOpType,
    txType,
    summary,
    loading
  } = useSelector(state => state.history);
  const dispatch = useDispatch();

  const rowOptions = [
    { key: '10', text: '10', value: '10' },
    { key: '25', text: '25', value: '25' },
    { key: '50', text: '50', value: '50' },
    { key: '100', text: '100', value: '100' }
  ];

  const resOptions = [
    { key: 1, text: Translate.t('Cobros recientes'), value: '<-' },
    { key: 2, text: Translate.t('Cobro diario'), value: '<-d' },
    { key: 3, text: Translate.t('Cobro semanal'), value: '<-s' },
    { key: 4, text: Translate.t('Cobro mensual'), value: '<-m' },
    { key: 5, text: Translate.t('Pagos recientes'), value: '->' },
    { key: 6, text: Translate.t('Reembolsos'), value: 'r->' }
  ];
  
  useEffect(() => {
    dispatch(resetState());
    let dateFrom = new Date();
    let dateTo = new Date();
    dateFrom.setMonth(dateTo.getMonth() - 1);
    dispatch(
      getData('/extapi/Logged/ListCobrosEcte',
      dateTo.toISOString(),
      dateFrom.toISOString(),
      'POST')
    );
  },[])

  const onSelectOptionsChange = (event, { value }) => {
    let opcion = value;
    let dateFrom = new Date();
    let dateTo = new Date();
    switch (opcion) {
      case '<-':
        dateFrom.setMonth(dateTo.getMonth() - 1);
        dispatch(setTransactionsParameters('<-', 'CVCO'));
        dispatch(getData('/extapi/Logged/ListCobrosEcte', dateTo.toISOString(), dateFrom.toISOString(), 'POST'));
        break;
      case '<-d':
        dateFrom.setHours(dateFrom.getHours() - 23);
        dispatch(setTransactionsParameters('<-', 'CVCO', true));
        dispatch(getData('/extapi/Logged/ListCobrosEcte', dateTo.toISOString(), dateFrom.toISOString(), 'POST'));
        break;
      case '<-s':
        dateTo.setDate(dateTo.getDate() - 7);
        dispatch(setTransactionsParameters('<-', 'CVCO', true));
        dispatch(getData('/extapi/Logged/ListCobrosEcte', dateFrom.toISOString(), dateTo.toISOString(), 'POST'));
        break;
      case '<-m':
        dateTo.setMonth(dateTo.getMonth() - 1);
        dispatch(setTransactionsParameters('<-', 'CVCO', true));
        dispatch(getData('/extapi/Logged/ListCobrosEcte', dateFrom.toISOString(), dateTo.toISOString(), 'POST'));
        break;
      case '->':
        dispatch(setTransactionsParameters('->', 'PCOM'));
        dispatch(getData('/extapi/Logged/ListEctWallet/0/300', null, null));
        break;
      case 'r->':
        dispatch(setTransactionsParameters('->', 'DCOE'));
        dispatch(getData('/extapi/Logged/ListEurosWallet/0/300', null, null));
        break;
      default:
        dispatch(setTransactionsParameters('<-', 'PCOM'));
        dispatch(getData('/extapi/Logged/ListEurosWallet/0/300'));
        break;
    }
  }

  const onSelectResultsChange = (event, { value }) => {
    dispatch(setTransactionsPerPage(value));
  }

  return (
    <div>
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <Message style={{marginLeft: '5%'}} compact negative hidden={!errorMessage}>
            <Message.Header>Error</Message.Header>
            <p>{errorMessage}.</p>
          </Message>
        </div>
      </div>
      <div className="ui one column stackable center aligned page grid">
        <div className="column twelve wide">
          <span className="ResPerPageLegend">{Translate.t('Resultados por página')}: </span>
          <Menu compact>
            <Dropdown 
              simple 
              item
              defaultValue="10"
              options={rowOptions}
              onChange={ onSelectResultsChange } />
            <Dropdown simple item
              placeholder={Translate.t('Cobros recientes')}
              options={resOptions}
              onChange={ onSelectOptionsChange } />
          </Menu>
        </div>
      </div>
      <HistoryTable
        transactions={transactions}
        transactionsPerPage={transactionsPerPage}
        summary={summary}
        valOpType={valOpType}
        txType={txType}
        loading={loading} />
    </div>
  );
}

export default Paginator;

