import {
  QRINVOICE_CANCEL_CHARGE,
  QRINVOICE_ERROR,
  QRINVOICE_RESET_STATE,
  QRINVOICE_PAYMENT_SUCCESS,
  QRINVOICE_RESET_TO_RESULT_PAYMAENT
} from './types';
import Translate from '../utils/Translate';
import BackOfficeEndpoints from '../api/BackOfficeEndpoints';

export const qrInvoiceResetState = () => dispatch => {
  dispatch({ type: QRINVOICE_RESET_STATE });
};

export const qrInvoicePermissionDenied = message => dispatch => {
  dispatch({ type: QRINVOICE_ERROR, payload: message });
};

export const qrInvoiceCancel = () => {
  return { type: QRINVOICE_CANCEL_CHARGE };
};

export const qrInvoiceUpdateFirebaseID = fcm_token => async dispatch => {
  const token = sessionStorage.getItem('Token');
  const params = {
    'method': 'POST',
    headers: {
      'content-type': 'application/json;charset=utf-8',
      'authorization': `Bearer ${token}`
    },
    data: { FirebaseId: `${fcm_token}W` },
    'url': `/extapi/Logged/UpdateFireBaseId`
  };
  try {
    await BackOfficeEndpoints(params);
  } catch (err) {
    if (err.response.status === 401) {
      dispatch({ 
        type: QRINVOICE_ERROR, 
        payload: Translate.t('Sesion expirada. Por favor inicie sesion nuevamente') 
      });
    }
  }
};

export const qrInovicePaymentSuccess = payload => ({ type: QRINVOICE_PAYMENT_SUCCESS, payload });

export const resetToResultPayment = () => ({ type: QRINVOICE_RESET_TO_RESULT_PAYMAENT });